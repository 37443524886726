import React from "react"
import styled from "styled-components"
import Layout from "../layouts/layout"
import SEO from "../components/seo"
import Intro from "../components/case-studies/intro"
import Background from "../components/case-studies/background"
import Highlight from "../components/case-studies/highlight"
import Quote from "../components/case-studies/quote"
import SectionHeader from "../components/case-studies/section-header"
import Button from "../components/button"
import { primaryLight } from "../components/themes"
import { FaArrowRight } from "react-icons/fa"
import FeaturedImage from "../components/case-studies/featured-image"
import Blocks from "../components/case-studies/blocks"
import Footer from "../components/case-studies/footer"
import { Images } from "../images"
import { blueHeading } from "../components/themes"
import { Colors, Spacing } from "../styles/variables"
import ColorHeader from "../components/case-studies/color-header"
import InlineImage from "../components/case-studies/inline-image"
import { Link } from "gatsby"
import InlineHeader from "../components/case-studies/inline-header"

const SwaasthyPage = () => (
    <Layout>
        <SEO title="Swaasthy" />
        <Intro
            heading={"Swaasthy : Google Solution Challenge 2021"}
            subheading={
                "An app made to uplift user's health. It contains everything from medicine reminder functionality to make an SOS call to nearby ambulances to getting an appointment with a virtual doc. We believe that this is a revolutionary step necessary in bringing down the death-rate due to delay faced by patients in our country."
            }
            imageSrc={Images.SwaasthyHero}
            imageAlt={"Swaasthy : The only health app you'll ever need"}
        />
        <Background
            role={
                "Led all design & development efforts with a team of three developers"
            }
            tools={
                "Adobe XD, Flutter, Firebase, Tensorflow, Actions on Google, DialogFlow"
            }
        >
            In January of 2021, I came across Google DSC Solution Challenge 2021 and
            how it focused on solving critical world problems on the basis of{" "}
            <a href="https://sdgs.un.org/goals" target="_blank">
                United Nations 17 Sustainable Development Goals
            </a>
            . So, I reached out to a couple of my fellow devs and we started
            brainstorming on a bunch of ideas until we finally agreed upon solving the
            alarming health emergency situation in India.
        </Background>
        <Quote
            quote={
                "Swaasthy promotes education and economic growth by providing first responders with valuable training opportunities when they sign up via the app."
            }
            source={
                "Erica Hanson, Global Program Manager, Google Developer Student Clubs"
            }
        />
        <SectionHeader heading="Determining scope & priorities as a group">
            We began by defining the problem we were trying to solve with a
            comprehensive audit of already existing solutions. We met with a bunch of
            stakeholders our friends who were currently in medical colleges,
            front-line health workers, relatives and other target groups who've been
            affected by the pandemic.
            <br /> <br />
            <Highlight>
                This helped us in more clearly defining a roadmap for what we wanted to
                build and the key elements to focus on in the next phase of the project.
            </Highlight>{" "}
            At the same time, it certainly helped us a lot in splitting up the
            responsibilities of the team and helped us align as one before the work
            began.
        </SectionHeader>
        <FeaturedImage
            imageSrc={Images.SwaasthyBoard1}
            imageAlt={"Swaasthy Design : Brainstorm Board"}
        />

        <InlineHeader heading="User Personas">
            <img style={{ width: '150%' }} src={Images.SwaasthyPersona1} />
            <img style={{ width: '150%' }} src={Images.SwaasthyPersona2} />
        </InlineHeader>

        {/* <InlineFeatureImage
      imageSrc={Images.SwaasthyPersona1}
      imageAlt={"Swaasthy Design : Brainstorm Board"}
    /> */}

        <SectionHeader heading="Atomic design methodology">
            I'm a big fan of atomic design systems for larger projects! Especially,
            since we were working with Flutter, which uses a component-based approach,
            or more commonly referred to as 'Widgets' in Flutter. I chose to build a
            simple, playful yet intutive design system that would be used across the
            app.
            <Divider />
            Since there were a lot of micro-services in the app, which were most of
            the times independent of each other, I decided to give a separation
            between these using different accent colors for each section.
            <Divider />
        </SectionHeader>

        <ColorHeader heading="Primary Color Palette">
            These colors consist of our core brand elements. As a guideline, I used to
            look at these colors first when choosing a color palette for the UI
            elements. Especially, when referencing buttons, headers and other
            controls.
            <InlineImage
                imageSrc={Images.SwaasthyPrimaryColor}
                imageAlt={"Swaasthy Design : Primary Color Palette"}
            />
        </ColorHeader>

        <ColorHeader heading="Alt Color Palette">
            These alt colors are slight variations of the primary color palette,
            typically shifts down in the shade. These are primarily used for hover
            effects and other subtle interactions.
            <InlineImage
                imageSrc={Images.SwaasthyAltColor}
                imageAlt={"Swaasthy Design : Alt Color Palette"}
            />
        </ColorHeader>

        <SectionHeader heading="Final Artboard">
            With all the colors and typography in place, I started to build the
            mockups and after countless iterations and sleepless nights, I finally
            with the following design system.
        </SectionHeader>
        <FeaturedImage
            imageSrc={Images.SwaasthyArtboard}
            imageAlt={"Swaasthy Design : Final Artboard"}
        />

        <SectionHeader heading="Development">
            Since I want this blog to be more focused on the 'design' thought process,
            I won't dig much deeper into how the development process seemed like.{" "}
            <Highlight>
                P.S. It was a crazy experience! We built almost all the services from
                the ground up. Up, running and fully functional in less than two weeks!
            </Highlight>
            <Divider />
            Here's a quick glimpse of how our screens looked like for those 14 days!
            🙂 If you're more curious to know about it, check out the GitHub
            Repository for this project.
            <ButtonLink href="https://github.com/ishandeveloper/Swaasthy">
                <Button
                    theme={primaryLight}
                    label={"View GitHub Repository"}
                    icon={<FaArrowRight />}
                />
            </ButtonLink>
        </SectionHeader>

        <FeaturedImage
            imageSrc={Images.SwaasthyDevelopment}
            imageAlt={"Swaasthy : Flutter Development"}
        />

        <SectionHeader heading="Did I mention?">
            We also created a Google Assistant Action (formerly known as Actions on
            Google) and here's how that panned out!
        </SectionHeader>
        <FeaturedImage
            imageSrc={Images.SwaasthyGoogleHome}
            imageAlt={"Swaasthy : Google Home"}
        />

        <SectionHeader heading="User Validation">
            Once the app was built, The next most crucial step was to validate our
            solution, whether people would actually something like this and see the
            product-market fit.{" "}
            <Highlight>
                So, we tested our app in a 40 users in our close circle (consisting of
                both average users & medical volunteers) in Ropar. We used a few
                different methods to validate our solution.
            </Highlight>
            <br />
            <br />
            This did help us a lot in getting a better understanding of the different
            use cases. For example :
        </SectionHeader>

        <Blocks
            theme={blueHeading}
            headingOne="Medicine Reminder?"
            descriptionOne="It's not something we came up with initially, but was later added since we
            noticed that most of the Patients don't take medicines at the pre-set
            time. Some even hide medicines from their caretakers!"
            headingTwo="Doctor Specialistion Recommendation"
            descriptionTwo="We observed this mostly in a rural area, where most of the times patients don't know which type of doctor(specialist) to consult for a specific disease..Hence we added this feature to help them find the best doctor for their specific disease."
            headingThree="Online Medical Appointmnets"
            descriptionThree="We determined that in many regions/areas there is a lack of quality doctors in patient's vicinity/neighbourhood. Hence we added this feature to help patients find the best doctor near them."
        />

        <SectionHeader heading="The Pitch!">
            As a part of the submisison process, we were asked to prepare a short
            video of 2 mins to pitch our proposed solution. Here's what we came up
            with!
            <br />
            <br />
            <iframe
                width="800"
                height="450"
                src="https://www.youtube-nocookie.com/embed/dinzfBUDqi0"
                title="Swaasthy"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
        </SectionHeader>

        <SectionHeader heading="Top 10 Finalists">
            Fast forward by a couple of months, Google announced the top 10 finalists
            for Solution Challenge and as you guessed it, we were among them!{" "}
            <Highlight>
                In fact, we were among, one of the only two teams from India!
            </Highlight>{" "}
            <br />
            <br /> Because of this, we were also featured on Google Developers Blog,
            along with that we were invited to pitch our solution to the world for one
            last time, in a livestream event on Google Developers Youtube channel.
            Boy, that was awesome!
            <ButtonLink
                href="https://developers.googleblog.com/2021/07/dsc-students-building-better-world.html"
                target="_blank"
            >
                <Button
                    theme={primaryLight}
                    label={"Google Developers Blog Post"}
                    icon={<FaArrowRight />}
                />
            </ButtonLink>
            <Divider />
            As a part of Solution Challenge, Every year Google invites all the top 10
            teams to Google I/O in San Francisco! However, because of the pandemic,
            Google I/O went virtual this year and we missed out on this opportunity
            😕.
        </SectionHeader>

        <SectionHeader heading="The Livestream!">
            Here's the pitch along with the Q&A that we made during here's the
            livestream of the event! You can also check out the same at :{" "}
            <b>
                <a href="https://goo.gle/swaasthy" target="_blank">
                    goo.gle/swaasthy
                </a>
            </b>
            <br />
            <br />
            <br />
            <iframe
                width="800"
                height="450"
                src="https://www.youtube-nocookie.com/embed/ETx-2Gd79DE"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
            ></iframe>
        </SectionHeader>

        <SectionHeader heading="Takeaways">
            Even though we couldn't get into the Top 3! Because, trust me all the
            other solutions were equally impressive! I certainly learnt a lot from
            this entire experience.
            <br />
            <br />
            <Highlight>
                I would certainly carry forward the prioritization exercise that we
                conducted during our initial research
            </Highlight>
            , as it helped to easily plan a roadmap and distribute the work amongst
            all the team members. This, in addition to highly communicative and
            transparent progress updates, helped prevent any pivots and kept our
            velocity strong.
            <br />
            <br /> Moving forward, I would work to incorporate the development team
            more into the early decision process. But, it was a hell of an experience!
        </SectionHeader>
        <Footer />
    </Layout>
)

const Divider = styled.hr`
  border: 1px solid ${Colors.gray1};
  width: ${Spacing.base};
  margin: ${Spacing.base} 0;
`

const ButtonLink = styled.a`
  display: flex;
  text-decoration: none;
  margin-top: ${Spacing.base};
`

export default SwaasthyPage
