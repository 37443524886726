import React from "react"
import styled from "styled-components"
import Fade from "react-reveal/Fade"
import { Colors, Spacing, Typography } from "../../styles/variables"

const ColorHeader = ({ heading, children }) => (
    <Fade>
        <Container>
            <Heading>{heading}</Heading>
            <Description>{children}</Description>
        </Container>
    </Fade>
)

const Container = styled.section`
  padding: ${Spacing.base} ${Spacing.sectionPadding} 0
    ${Spacing.sectionPadding};
    margin-left: ${Spacing.base};

  @media (max-width: ${Spacing.breakPoint}) {
    padding: ${Spacing.huge} ${Spacing.sectionPaddingMobile} 0
      ${Spacing.sectionPaddingMobile};
  }
`

const Heading = styled.h2`
  font-size: ${Typography.mediumFontSize};
  margin-bottom: ${Spacing.small};
  max-width: 15em;
`

const Description = styled.p`
  color: ${Colors.gray4};
  max-width: 40em;
//   margin-left: 1em;

  @media (max-width: ${Spacing.breakPoint}) {
    font-size: ${Typography.smallFontSize};
  }
`

export default ColorHeader
